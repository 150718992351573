import React from 'react';
import './style/footer.css'

const Footer = () => {
    return (
        <footer>
            <p>
                © 2019 Abrespacio. Todos los derechos reservados. Sitio web desarrollado por Daniel Radrigán
            </p>
        </footer>
    )
}

export default Footer;