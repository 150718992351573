import React from 'react'

class ProjectDetail extends React.Component{
    constructor(props){
        super(props);
    }
    render(){
        const proyecto = this.props.Proyecto;
        const año = ()=>{
            if (proyecto.año){
                return <p className="Text-year">Año: {proyecto.año}</p>
            }
        }
        const comuna = ()=>{
            if (proyecto.comuna){
                return <p className="Text-place">Comuna: {proyecto.comuna}</p>
            }
        }
        const tamaño = ()=>{
            if (proyecto.tamaño){
                return <p className="Text-place">Tamaño: {proyecto.tamaño} m<sup>2</sup></p>
            }
        }
        const description = proyecto.descripcion.map((e) => 
        <p className="Text-description">
            {e}
        </p>
        )
        const img = proyecto.imagenes.map((e)=>
        <figure className="img-wraper">
            <img className="Images-img" src={e} alt="" />
        </figure>
        )
        return(
            <>
                <section className="Text">
                    <h1 className="Text-title">{proyecto.nombre}</h1>
                    <div>
                        {año()}
                        {comuna()}
                        {tamaño()}
                    </div> 
                    {description}
                </section>
                <section className="Images">
                    {img}
                </section>
            </>
        )
    }
} 

export default ProjectDetail;