import React from 'react'
import ContactoMorado from '../component/ContactoMorado'
import './style/home.css'
import Cascada from '../recursos/Portadas/Cascadas.Toña8.jpg'
import Foto2 from "../recursos/Proyectos/jardines-privados/Via azul 4.jpg"
import SanIgnacio from "../recursos/Proyectos/San-ignacio-el-bosque/San-Ignacio-web 600x400.jpg"
import CDUC from "../recursos/Proyectos/area-pic-nic-CDUC/CDUC-front-web600x400.jpg"
import LaEstrella from "../recursos/Proyectos/Plaza-la-estrella/Plaza-La-Estrella 600x400.jpg"
import Oasis from "../recursos/Proyectos/parque-oasis-calama/PLAZA DE JUEGOS Y PIMIENTO600x400.jpg"
import Magdalena from "../recursos/Nosotros/Magdalena Barros McIntosh-500px.jpg"
import Jimena from "../recursos/Nosotros/Jimena Alvarez Margarit-500px.jpg"
import Valeria from "../recursos/Nosotros/Valeria Rodriguez-500px.jpg"
import Claudio from "../recursos/Nosotros/Claudio de la Cerda-500px.jpg"
import Francisca from "../recursos/Nosotros/Francisca Oteiza Fierro-500px.jpg"
import ProjectInfo from '../component/ProjectInfo'
import ProyectBand from '../component/ProyectBand'

function Home() {
  return (
    <>
      <section className="hero">
        <h1>...NUESTRA INSPIRACIÓN ES LA NATURALEZA...</h1>
        <figure className="hero-index-image">
          <img src={Cascada} alt="foto portada" />
        </figure>
        <article>
            <figure>
                <img src={Foto2} alt="foto" />
            </figure>
            <div>
                <h4>Arquitectura y</h4>
                <h2> Paisajismo</h2>
                <p>
                    <strong>
                    Acercamos la NATURALEZA a la 
                    vida cotidiana de la CIUDAD, los 
                    BARRIOS, las FAMILIAS para 
                    que cada PERSONA que recorre 
                    nuestros paisajes tenga una 
                    EXPERIENCIA que nos acerque a 
                    NUESTRO PAISAJE
                    </strong>
                </p>
                <a href="nosotros.php">Saber Más</a>
            </div>
        </article>
      </section>
        <section className="Home-proyect">
            <h2>Nuestros Proyectos</h2>
            <div className="Proyectos-carrousel">
                <ProjectInfo 
                    url = "/"
                    name = "San ignacio del Bosque"
                    img = {SanIgnacio}
                />
                <ProjectInfo 
                    url = "/"
                    name = "Club Deportivo Universidad Católica"
                    img = {CDUC}
                />
                <ProjectInfo 
                    url = "/"
                    name = "Plaza La Estrella"
                    img = {LaEstrella}
                />
                <ProjectInfo 
                    url = "/"
                    name = "Parque Oasis Calama"
                    img = {Oasis}
                />
            </div>
            <ProyectBand msg1="Revisa nuestros proyectos"/>
        </section>
        <section className="Nosotros">
            <h2>Nuestro Equipo</h2>
            <div>
                <article className="Magdalena">
                    <figure>
                        <img src={Magdalena} alt="Foto Magdalena Barros" />
                    </figure>
                    <p>
                        <strong>Magdalena Barros McIntosh</strong><br />
                        Directora Abrespacio
                    </p>
                </article>
                <article className="Jimena">
                    <figure>
                        <img src={Jimena} alt="Foto Jimena Álvarez" />
                    </figure>
                    <p>
                        <strong>Jimena Álvarez</strong><br />
                        Arquitecta
                    </p>
                </article>
                <article className="Valeria">
                    <figure>
                        <img src={Valeria} alt="Foto Valeria Rodriguez" />
                    </figure>
                    <p>
                        <strong>Valeria Rodriguez Pérez</strong><br />
                        Ing. Agrónomo, Paisajista y Huertera Urbana
                    </p>
                </article>
                <article className="Claudio">
                    <figure>
                        <img src={Claudio} alt="Foto Claudio De la Cerda" />
                    </figure>
                    <p>
                        <strong>Claudio De La Cerda</strong><br />
                        Ingeniero Agrónomo
                    </p>
                </article>
                <article className="Francisca">
                    <figure>
                        <img src={Francisca} alt="Foto Francisca Oteíza"/>
                    </figure>
                    <p>
                        <strong>Francisca Oteíza Fierro</strong><br />
                        Arquitecta del paisaje
                    </p>
                </article>
            </div>
        </section>
        <ContactoMorado/>
    </>
  );
}

export default Home;
