import React from 'react'
import ProyectBand from '../component/ProyectBand'
import Portada from "../recursos/Portadas/Victoria16.jpg"
import './style/contacto.css'

class Contacto extends React.Component {
  constructor (props){
    super(props)
    this.state = {
      email: "",
      name: "",
      msg: ""
    }
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  handleChange = e =>{
    const {name, value} =e.target
    this.setState({...this.state, [name]: value})
  }
  handleSubmit(event){
    event.preventDefault();
    console.log(this.state)
    fetch(/* "http://localhost/abrespacio/correo.php" */"https://www.abrespacio.cl/correo.php", {
      method: "POST",
      body: JSON.stringify(this.state),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(
      (response) => (response.json())
    ).then(
      (response)=> {
        console.log(response);
        if (response.status === "success"){
          console.log("Message Sent")
          this.resetForm()
        } else if (response.status === "fail"){
          console.log("Message failed to Send")
        }
      }
    )
  }
  resetForm(){
    this.setState({
      email: "",
      name: "",
      msg: ""
    })
  }
  render(){
    return (
      <>
        <section className="hero heros">
          <h1>Contacto</h1>
          <img className="hero-image" src={Portada} />
        </section>
        <section className="formulario">
          <h2>Estemos en contacto</h2>
          <p>
            Email: magdalena.abrespacio@gmail.com 
          </p>
          <form id="form" onSubmit={this.handleSubmit}>
            <input id="name" className="nombre text" type="text" placeholder="Nombre y Apellido" name="name" required="" onChange={this.handleChange} value={this.state.name}/>
            <input id="email" className="email text" type="email" placeholder="Tu dirección de email" name="email" required="" onChange={this.handleChange} value={this.state.email} />
            <textarea id="msg" className="mensaje text" placeholder="Escribenos tu mensaje y te responderemos" name="msg" required="" onChange={this.handleChange} value={this.state.msg} ></textarea>
            <input type="submit" value="Enviar" name="enviar" className="boton" />
          </form>
        </section>
        <ProyectBand msg1='Tienes un proyecto en mente, ' msg2="revisa los nuestos"/>
      </>
    );
  }
}
  
export default Contacto;