import React from 'react';
import {Link} from 'react-router-dom';
import logo from '../recursos/Logo-Abrespacio.png'
import './style/header.css'

const Header = () =>{
    return(
        <header>
            <figure>
                <img src={logo} alt="logo abrespacio" />
            </figure>
            <div className="menu">
                <i className="icon-menu"></i>
                <nav>
                    <ul>
                        <li>
                            <Link to="/">Inicio</Link>
                        </li>
                        <li>
                            <Link to="nosotros">Nosostros</Link>
                        </li>
                        <li>
                            <Link to="proyectos">Proyectos</Link>
                        </li>
                        <li>
                            <Link to="otros">Otros</Link>
                        </li>
                        <li>
                            <Link to="contacto">Contacto</Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
    )
}

export default Header;