import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => (
    <>
        <h1>No encontrado</h1>
        <Link to="/" >volver</Link>
    </>
);

export default NotFound;