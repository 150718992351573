import React from 'react';
import ContactoMorado from '../component/ContactoMorado'
import './style/otros.css'
import Portada from "../recursos/Portadas/Humedal conchalí 2015. cactus.jpg"

function Otros() {
    return (
      <>
        <section className="hero heros">
          <h1>Otros</h1>
          <img className="hero-image" src={Portada} />
        </section>
        <section className="publicaciones">
            <a href="http://revistaplaneo.cl/2018/09/29/apropiacion-del-espacio-publico-diseno-participativo-en-villa-el-cabildo-en-el-contexto-del-programa-quiero-mi-barrio-2014/" target="_blank">
                Publicación revista planeo
            </a>
            <br />
            <a href="" target="_blank">
                Concurso LALI Buenas Practicas Latinoamericanas en el Paisaje
            </a>
            <br />
            <a href="http://www.plataformaurbana.cl/archive/2017/01/15/proyecto-plaza-la-estrella-de-pudahuel-es-premiado-en-concurso-latinoamericano-del-paisaje/" target="_blank">
                Concurso LALI Buenas Practicas Latinoamericanas en el Paisaje 2
            </a>
            <br />
            <a href="https://www.tysmagazine.com/seleccion-lali-buenas-practicas-paisaje-latinoamerica/" target="_blank">
                Concurso LALI Buenas Practicas Latinoamericanas en el Paisaje 3
            </a>
            <br />
            <a >
                Coordinación FONDART 2010 - Parque por la Paz Villa Grimaldi
            </a>
            <br />
            <a >
                Coordinación cuenta publica participativa - FOSIS
            </a>
        </section>
        <ContactoMorado />
      </>
    );
  }
  
  export default Otros;