import react from 'react';
import Header from './Header';
import Footer from './Footer';
import './style/Layout.css'

const Layout = ({children}) =>{
    return (
        <>
            <Header />
            {children}
            <Footer />
        </>
    )
}

export default Layout;