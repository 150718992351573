import React from 'react'
import {Link} from 'react-router-dom'
import './style/proyectBand.css'

const ProyectBand = (props) =>{
    return(
        <div className="ProyectBand">
          <p>{props.msg1}<br />{props.msg2}</p>
          <Link to="proyectos">Ver Proyectos</Link>
        </div>
    )
}
export default ProyectBand;