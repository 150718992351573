import React from 'react'
import { Link } from 'react-router-dom';

const ContactoMorado = () =>{
    return (
    <section className="Contacto">
        <div className="Contacto-text">
            <p>
                Contacto
            </p>
            <p>
                <small>
                    Email: magdalena.abrespacio@gmail.com
                </small>
            </p>
        </div>
        <Link className="Contacto-boton" to="contacto">Contactanos</Link>
    </section>
    )
}

export default ContactoMorado;