import React from 'react';
import ContactoMorado from '../component/ContactoMorado';
import ProjectDetail from '../component/ProjectDetail';
import data from '../proyectos.json';
import './style/proyectos.css'

const Proyecto = (props) =>{
    const ID = props.match.params.id;
    const PROYECTO = ()=>{
        for (let index = 0; index < data.length; index++) {
            if(Object.values(data[index])[0].nombre === ID){
                console.log(Object.values(data[index])[0])
                return Object.values(data[index])[0];
            }
        };
    }
    return (
        <>
        <ProjectDetail
            Proyecto = {PROYECTO()}
        />
        <ContactoMorado />
        </>
    )
}
export default Proyecto;