import React from 'react';
import {Link} from 'react-router-dom'
import './style/ProjectInfo.css'

const ProjectInfo = (props) =>{
    return (
        <div className="ProjectInfo">
            <Link to={props.url}>
                <p className="ProjectInfo-name">{props.name}</p>
                <figure>
                    <img src={props.img} alt="" />
                </figure>
            </Link>
        </div>
    )
}

export default ProjectInfo;