import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import Home from './Pages/Home';
import Nosotros from './Pages/Nosotros';
import Proyectos from './Pages/Proyectos';
import Proyecto from './Pages/Proyecto';
import Otros from './Pages/Otros';
import Contacto from './Pages/Contacto';
import NotFound from './Pages/NotFound';
import Layout from './component/Layout';


const App = () => {
    return (
        <BrowserRouter>
            <Layout>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/nosotros" component={Nosotros} />
                    <Route exact path="/proyectos" component={Proyectos} />
                    <Route exact path="/otros" component={Otros} />
                    <Route exact path="/contacto" component={Contacto} />
                    <Route exact path="/proyecto-:id" component={Proyecto} />
                    <Route component={NotFound} />
                </Switch>
            </Layout>
        </BrowserRouter>
    );
};
export default App;