import React from 'react'
import ProjectInfo from '../component/ProjectInfo';
import ContactoMorado from '../component/ContactoMorado'
import './style/proyecto.css'
import Portada from "../recursos/Portadas/RNCoyhaique.Toña7.jpg"
import data from "../proyectos.json"

function Proyectos() {
  const proyectosInfo = data.map((e)=>
    <ProjectInfo 
      url={`/proyecto-${Object.values(e)[0].nombre}`}
      name = {Object.values(e)[0].nombre}
      img = {Object.values(e)[0].imagenes[0]}
      key= {Object.values(e)[0].nombre}
    />
  )
  return (
    <>
      <section className="hero heros">
        <h1>Proyectos</h1>
        <img className="hero-image" src={Portada} />
      </section>
      <section className="Proyectos">
        {proyectosInfo}
      </section>
      <ContactoMorado />
    </>
  );
}
  
export default Proyectos;