import React from 'react'
import ContactoMorado from '../component/ContactoMorado'
import './style/nosotros.css'
import Portada from "../recursos/Portadas/2000.toña.JPG"
import maceta1 from "../recursos/Nosotros/maceta-1.png"
import maceta2 from "../recursos/Nosotros/maceta-2.png"
import Magdalena from "../recursos/Nosotros/Magdalena Barros McIntosh-500px.jpg"
import Jimena from "../recursos/Nosotros/Jimena Alvarez Margarit-500px.jpg"
import Valeria from "../recursos/Nosotros/Valeria Rodriguez-500px.jpg" 
import Claudio from "../recursos/Nosotros/Claudio de la Cerda-500px.jpg"
import Francisca from "../recursos/Nosotros/Francisca Oteiza Fierro-500px.jpg"
import SanIgnacio from "../recursos/clientes/Logo san Ignacio El Bosque.png"
import Pudahuel from "../recursos/clientes/logo pudahuel 19.11.14.jpg"
import Seremi from "../recursos/clientes/LOGO SEREMI_Region_Metropolitana.jpg"
import Make from "../recursos/clientes/LOGO MAKE.jpg"
import Andree from "../recursos/clientes/andree.jpg"
import Teresianas from "../recursos/clientes/Logo Teresianas.png"
import UC from "../recursos/clientes/LACATOLICA.CL-sin-fondo.png"
import Correa from "../recursos/clientes/correa.jpg"
import Quieromibarrio from "../recursos/clientes/logo Quieo Mi Barrio Version Final.jpg"

function Nosotros() {
  return (
    <>
      <section className="hero heros">
        <h1>Nosostros</h1>
        <img className="hero-image" src={Portada} />
      </section>
      <section className="contenido">
          <article className="contenido-article">
              <figure className="contenido-imgwraper">
                  <img className="contenido-img" src={maceta1} alt="foto de huerta" />
              </figure>
              <div className="contenido-text">
                  <h4>Misión</h4>
                  <p>
                      Acercamos la naturaleza a las personas a través de intervenciones de arquitectura del paisaje, para que cotidianamente puedan conocerla, disfrutarla, valorarla y cuidarla.
                  </p>
              </div>
          </article>
          <article className="contenido-article">
              <figure className="contenido-imgwraper">
                  <img className="contenido-img" src={maceta2} alt="foto de maseteros" />
              </figure>
              <div className="contenido-text">
                  <h4>Visión</h4>
                  <p>
                      Trabajamos desde la identidad, a partir de sueños y necesidades que se materializan con coherencia ecológica desde el reconocimiento y valoración del paisaje local.
                  </p>
              </div>
          </article>
      </section>
      <section className="nosotros">
          <h2>Nuestro Equipo</h2>
          <article>
              <div>
                  <figure>
                      <img src={Magdalena} alt="Foto Magdalena Barros" />
                  </figure>
                  <h4>MAGDALENA BARROS MCINTOSH</h4>
                  <h5>DIRECTORA ABRESPACIO</h5>
              </div>
              <p>
                  <strong>
                      Arquitecta PUC, postítulo en Arquitectura del Paisaje PUC, Master en Proyectos de Arquitectura y Urbanismo FUNIBER, diplomada en intervención socio-territorial. 
                  </strong>
                  Con 20 años de docencia, actualmente participa en la escuela de arquitectura del paisaje Universidad Central y Estudio Paisaje.
                  Trabaja hace más de 15 años en proyectos de diseño y supervisión de obras de arquitectura del paisaje en espacios públicos y privados, ha desarrollado más de 30.000 m2 de proyectos de diseño certificados, trabajando en diferentes escalas, desde Planes Maestros de Arquitectura del Paisaje hasta plazas, parques y jardines privados.
                  El 2017 gana el concurso 
                  <strong>Buenas Prácticas Latinoamericanas en el Paisaje de Iniciativa latinoamericana del Paisaje LALI</strong>, 
                  en la línea Paisajes Sociales de con el proyecto Plaza La Estrella de Pudahuel, desarrollado en el contexto del Programa Quiero mi Barrio.
                  A partir de su experiencia en el desarrollo de proyectos de patios educativos, preside como socia fundadora de la Fundación 
                  <strong>RECREO EDUCA</strong>.
              </p>
          </article>
          <article>
              <div>
                  <figure>
                      <img src={Jimena} alt="Foto Jimena Alvarez" />
                  </figure>
                  <h4>JIMENA ÁLVAREZ MARGARIT</h4>
                  <h5>ARQUITECTA</h5>
              </div>
              <p>
                  <strong>Arquitecta, Pontificia Universidad Católica de Chile</strong>, M.D.I. Master en Dirección de Empresas Constructoras e Inmobiliarias Universidad Politécnica de Madrid.
                  Ejercicio profesional centrado en Consultorías orientadas a Proyectos de Arquitectura, Paisaje y Construcción de Jardines.
                  Actualmente trabaja en proyectos de Borde Costero, incorporando flora nativa de valor hornamental y considerando accesibilidad universal en balneareos, caletas, etc.
              </p>
          </article>
          <article>
              <div>
                  <figure>
                      <img src={Valeria} alt="Foto Valeria Rodriguez" />
                  </figure>
                  <h4>VALERIA RODRIGUEZ PÉREZ</h4>
                  <h5>INGENIERA AGRÓNOMO, PAISAJISTA Y HUERTERA URBANA</h5>
              </div>
              <p>
                  <strong>Ing. Agrónomo, Paisajista y Huertera Urbana con mención en manejo de recursos naturales</strong><br /> 
                  Por un poco más de 20 años en el rubro del Paisajismo, ha logrado ser
                  parte de equipos públicos y privados, desde la producción de plantas,
                  venta, diseño, construcción y mantención de áreas verdes. Incluyendo
                  además la Cocreación de senderos en áreas verdes para la observación
                  de flora y fauna en Áreas Protegidas.
                  <strong>Experiencia docente</strong><br />
                  Por casi 5 años a sido parte de Estudio Paisaje, con ello se han abierto
                  nuevas posibilidades ampliando experiencia en docencia en entidades
                  públicas y privadas como Minvu, Fantasilandia, Codeff, Juntas de
                  Vecinos, empresas privadas, entre otros, con temas relacionados al
                  árbolado urbano, huertas urbanas, suelo, aves locales y compostaje.
              </p>
          </article>
          <article>
              <div>
                  <figure>
                      <img src={Claudio} alt="Foto Claudio de la Cerda" />
                  </figure>
                  <h4>CLAUDIO DE LA CERDA</h4>
                  <h5>INGENIERO AGRÓNOMO, ESPECIALISTA EN RIEGO</h5>
              </div>
              <p>
                  <strong>Ingeniero Agrónomo de la Universidad de Chile</strong>, 
                  dedicado a soluciones tecnológicas en agua, cómo riego, sistemas de bombeo, piletas, cascadas y otros.  
                  Empresario desde 1995, dedicado al rubro de jardines.  
                  Amplia experiencia en construcción y mantención de áreas verdes además de diseño de riego en formatos manuales y digitales.  
                  <strong>Profesor de riego de jardines desde 2001 en Estudio Paisaje, Club de Jardines, INCACEA, INACAP, Universidad del Pacífico, DUOC UC y en Diplomados de Agronomía y Arquitectura de la Pontificia Universidad Católica</strong>. 
                  Gracias a múltiples trabajos realizados en el ambiente del paisajismo, se ha logrado integrar conocimientos vegetales, constructivos y ambientales con los últimos adelantos tecnológicos desarrollados en el área.  Participación en proyectos de riego como Parque inundable del Zanjón de la Aguada, Centro Cultural Gabriela Mistral, Palacio Baburizza, Fachadas verdes Parque Arauco, Enlaces de Autopista Costanera Norte, Corredor Dorsal de Transantiago, Fachada verde Hotel Radisson de Con-Con, Selva Viva Parque Araucano, jardines botánicos de La Serena y Vallenar, hospitales, colegios, edificios y muchos otros.
              </p>
          </article>
          <article>
              <div>
                  <figure>
                      <img src={Francisca} alt="Francisca Oteiza Fierro" />
                  </figure>
                  <h4>FRANCISCA OTEÍZA FIERRO</h4>
                  <h5>ARQUITECTA DEL PAISAJE</h5>
              </div>
              <p>
                  <strong>Arquitecto del Paisaje de la Universidad Tecnológica de Chile – INACAP titulada el año 2014</strong>. 
                  Destaca su participación en el programa quiero mi barrio entre los años 2015 y 2016, 
                  Apoyando el desarrollo del Proyecto «Revitalización Plaza la Estrella» y «Recuperación de Borde Barrial» para la Ilustre Municipalidad de Pudahuel, 
                  siendo este proyecto seleccionado en la primera <strong>Convocatoria de Iniciativa Latinoaméricana del Paisaje, LALI Buenas Prácticas 2016, categoría Paisajes Sociales</strong>. 
                  Además, haber colaborado en el Proyecto de Paisajismo “Patios Primer Ciclo”, Colegio San Ignacio El Bosque, Providencia.
              </p>
          </article>
      </section>
      <section className="Clientes">
          <h2>Quienes han Confiado en Nosotros</h2>
          <div className="Clientes-logos">
              <figure className="Clientes-2x4">
                  <img src={SanIgnacio} alt="Colegio San Ignacio del Bosque" />
              </figure>
              <figure className="Clientes-2x1">
                  <img src={Pudahuel} alt="Municipalidad Pudahuel" />
              </figure>
              <figure className="Clientes-2x1">
                  <img src={Seremi} alt="SEREMI Región Metropolitana" />
              </figure>
              <figure className="make">
                  <img src={Make} alt="Make arquitectura" />
              </figure>
              <figure className="Clientes-2x1">
                  <img src={Andree} alt="St. Andreé English school" />
              </figure>
              <figure className="Clientes-1x2">
                  <img src={Teresianas} alt="Colegio Teresiana" />
              </figure>
              <figure className="Clientes-1x2 catolica">
                  <img src={UC} alt="CD Universidad Católica" />
              </figure>
              <figure className="Clientes-2x1">
                  <img src={Correa} alt="Correa 3 Arquitectos" />
              </figure>
              <figure className="Clientes-2x1">
                  <img src={Quieromibarrio} alt="Programa quiero mi barrio" />
              </figure>
          </div>
      </section>
      <ContactoMorado />  
    </>
  );
}
  
export default Nosotros;